.projects {
    padding: 60px 20px;
    /* background-color: #fff; */
    color: #333;
    text-align: center;
}

.projects h2 {
    font-size: 36px;
    margin-bottom: 40px;
}

.projects-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    margin-top: 20px;
}

.project-card {
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
}

.project-card h3 {
    font-size: 24px;
    margin-bottom: 10px;
}

.project-card p {
    font-size: 16px;
    margin-bottom: 15px;
}

.project-card a {
    text-decoration: none;
    color: #61dafb;
    font-weight: bold;
}

.project-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.projects.dark {
    color: white;
}