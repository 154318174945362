.contact {
    padding: 60px 20px;
    background-color: none;
    color: #333;
    text-align: center;
}

.contact h2 {
    font-size: 36px;
    margin-bottom: 40px;
}

.contact-form {
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.form-group {
    display: flex;
    flex-direction: column;
}

.form-group label {
    margin-bottom: 10px;
    font-size: 16px;
}

.form-group input,
.form-group textarea {
    padding: 10px;
    font-size: 16px;
    border-radius: 20px;
    border: 1px solid #ddd;
    background-color: #282c34;
    color: white;
}

.form-group textarea {
    resize: vertical;
    min-height: 150px;
}

.submit-btn {
    padding: 12px 20px;
    font-size: 18px;
    background-color: #61dafb;
    border: none;
    border-radius: 10px;
    color: #282c34;
    cursor: pointer;
    transition: background-color 0.3s;
}

.submit-btn:hover {
    background-color: #21a1f1;
}

/* dark mode styles */
.contact.dark {
    color: white;
}