.hero {
    height: 100vh;
    /* Full viewport height */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    padding: 0 0 20px;

    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;

}

.hero video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
    z-index: -1;
}

.hero-content h1 {
    font-size: 48px;
    margin-bottom: 20px;
}

.hero-content p {
    font-size: 24px;
    margin-bottom: 30px;
}

.hero-btn {
    padding: 10px 20px;
    font-size: 18px;
    color: white;
    background-color: #61dafb;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s;
}

.hero-btn:hover {
    background-color: #21a1f1;
}

.profile-pic {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    margin-bottom: 20px;
    border: 3px solid #282c34;

}