.about {
    padding: 60px 20px;
    background-color: none;
    color: #333;
    display: flex;

}

.about-content {
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* border: 3px solid #61dafb; */

}

.about-text {
    display: flex;
    text-align: center;
    margin-bottom: 40px;
    align-items: center;

    /* Centers vertically */

}

.about-text h2 {
    font-size: 36px;
    margin-bottom: 20px;
}

.about-text p {
    font-size: 18px;
    line-height: 1.6;
}

.about-skills {
    text-align: center;
}

.about-skills h3 {
    font-size: 28px;
    margin-bottom: 20px;
}

.about-skills ul {
    list-style: none;
    padding: 0;
}

.about-skills li {
    font-size: 18px;
    margin: 10px 0;
}


.about-description {
    padding: auto;

}

/* .about-skills h3.dark {
    color: white;
    border: 3px solid #61dafb;

} */

/* darkmode */
.about.dark {
    color: white;
}